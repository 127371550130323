@import "src/themes/daikiri/styles/index.scss";
.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: space(1.5);
  table-layout: fixed;

  &_wrapper {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  thead tr {
    background-color: getvar($colors, "primary", "50");

    th {
      text-align: center;
      padding: space(0.75) space() space(0.75) space();
      font-weight: 700;
      font-size: space(0.875);
      line-height: normal;

      @include sm {
        font-size: space(1.125);
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid getvar($colors, "neutral", "300");

      td {
        text-align: center;
        padding: space(0.875) space() space(0.875) space();
        font-size: space(0.875);
        font-weight: 400;
        line-height: space(1.125);
      }
    }
  }
}
